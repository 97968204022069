.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #333;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.com-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100vw;
  height:100vh;
  justify-content: center;
  transform: translate3d(0,0,0);
  // position: fixed;
  // left:0;
  // top:0;
  .progress {
    color: #aaa;
  }
  .earth{ 
    width: 100%;
  }
}