.switch-camera {
    position: fixed; z-index: 3;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px; height: 80px;
    .switch-camera-container {
        position: relative;
        .switch-camera-point {
            background-color: #333;
            width: 60px; height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-shadow: 0px 0px 5px #666;
        }
        .camera-list {
            position: absolute;
            right: 0;
            bottom: 60px;
            .camera-item {
                margin: 10px 0;
                background-color: #333;
                color: #fff;
                padding: 10px 15px;
                border-radius: 20px;
                font-size: 14px;
                display: flex;
                .checkbox {
                    background-color: #333;
                    width: 15px; 
                    height: 15px;
                    border: 1px solid #666;
                    border-radius: 50%;
                }
                .text {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 150px;
                    margin-left: 5px;
                }
                &.active {
                    background-color: #666;
                    .checkbox {
                        border-color: #fff;
                        background-color: #fff;
                    }
                }
            }
        }
    }
}