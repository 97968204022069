.prompt{
  position: fixed;
  left: 30px;
  right:30px;
  top:20px;
  .prompt-box{
    width: 100%;
    height: 70px;
    background:rgba(1,1,1,0.8);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content:space-around;
    img{height: 60%;}
    .box-text{line-height:1.5;color:#AAA69E;}
  }
}